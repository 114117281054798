
<template>
  <div class="createTest testPaper" @click.self="edit(-1, -1)">
    <div class="w">
      <div class="header"></div>
      <!-- 试卷信息 -->
      <div class="title">
        <el-row>
          <el-col span="6">
          试卷名称:<el-input
            v-model="testData.paperName"
            class="paperName"
            placeholder="请输入内容"
            style="width: 70%"
          ></el-input>
          </el-col>
          <el-col span="6" >
            类型:<el-select v-model="testData.type" placeholder="请选择" style="width: 70%">
              <el-option
                  v-for="item in [
                      {value: 1,label: '语文'},
                      {value: 2,label: '数学'},
                      {value: 3,label: '英语'},
                      {value: 4,label: '自然科学'}
                  ]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-col>
<!--          答题时间-->
          <el-col span="6" >
            <div >
            答题时间:
            <el-input-number
                style="width: 60%"
              v-model="testData.time"
              controls-position="right"
              :step="10"
              size="mini"
              :min="1"
            />分钟
            </div>
          </el-col>
<!--          题目数量-->
          <el-col span="6">
            题目数量: 共
            {{
              topicNavIndex_mixin(4, sortedTopics[4].topic_content.length - 1)
            }}
            道
          </el-col>
          </el-row>
        <br>
        <el-row>
          <!--          总分-->
          <el-col span="6" >总分: {{ totalScore }} 分</el-col>
        <!--          及格分数-->
          <el-col span="6" >
            及格分数:
            <el-input-number
              v-model="testData.passMark"
              controls-position="right"
              :step="1"
              size="mini"
              :min="0"
              style="width: 60%"
              :max="totalScore"
            />
            分
          </el-col>
<!--保存试卷-->
          <el-col span="6" >
            <el-button
              size="mini"
              type="primary"
              @click="submit()"
              >保存试卷</el-button
            >
            <el-button
                size="mini"
                type="success"
                @click="viewTestPaper()"
            >预览试卷</el-button>
          </el-col>
        </el-row>

      </div>

      <div class="title">
      <el-row>
        <el-col :span="12">
          <el-form label-width="120px">
            <el-form-item label="字体样式">
              <el-select v-model="testData.selectedFontStyle">
                <!-- 英文字体 -->
                <el-option label="Arial" value="Arial, sans-serif"></el-option>
                <el-option label="Verdana" value="Verdana, Geneva, sans-serif"></el-option>
                <el-option label="Georgia" value="Georgia, serif"></el-option>
                <el-option label="Tahoma" value="Tahoma, Geneva, sans-serif"></el-option>

                <!-- 中文字体 -->
                <el-option label="宋体" value="SimSun, 宋体, sans-serif"></el-option>
                <el-option label="黑体" value="SimHei, 黑体, sans-serif"></el-option>
                <el-option label="微软雅黑" value="Microsoft YaHei, 微软雅黑, sans-serif"></el-option>
                <el-option label="新宋体" value="NSimSun, 新宋体, sans-serif"></el-option>
                <el-option label="楷体" value="KaiTi, 楷体, serif"></el-option>
                <el-option label="仿宋" value="FangSong, 仿宋, serif"></el-option>
                <el-option label="华文宋体" value="STSong, 华文宋体, sans-serif"></el-option>
                <el-option label="华文黑体" value="STHeiti, 华文黑体, sans-serif"></el-option>
                <el-option label="华文楷体" value="STKaiti, 华文楷体, serif"></el-option>
                <el-option label="华文仿宋" value="STFangsong, 华文仿宋, serif"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form label-width="120px">
            <el-form-item label="字体大小">
              <el-input-number v-model="testData.fontSize" :min="10" :max="24" controls-position="right"></el-input-number>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      </div>
<!--编辑试卷主体-->
      <div class="test-content" @click.self="edit(-1, -1)">

        <!-- 题目导航 -->
        <div
            class="topic-nav"
            :class="isFixed ? 'isFixed' : ''"
            :style="topic_nav_style"
        >
          <!-- 工具 -->
          <div class="tool">
            <transition name="el-zoom-in-top">
              <div v-show="!isEdit(-1, -1)">
                删除题目
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    title="删除"
                    @click="delTopic()"
                ></el-button>
              </div>
            </transition>
          </div>

          <!-- 导航按钮 -->
          <div
              style=" margin-top: 20px;"

              v-for="(topics, Topics_index) in sortedTopics"
              :key="Topics_index"
          >
            <span class="topic-nav-item" @click.self="edit(-1, -1)">
              <div class="nav-title" >
                 <i class="el-icon-question" v-if="topics.type===0"></i>
                 <i class="el-icon-check" v-if="topics.type===1"></i>
                <i class="el-icon-finished" v-if="topics.type===2"></i>
                <i class="el-icon-minus" v-if="topics.type===3"></i>
                <i class="el-icon-edit-outline" v-if="topics.type===4"></i>

               {{ topicTypeName_mixin(topics.type) }}
              </div>
              <span
                  class="topic-nav-button"
                  :class="editInedx.type == topics.type"
                  v-for="(item, index) in topics.topic_content"
                  :key="index"
                  @click="topicNav(Topics_index, index)"
              >
                {{ topicNavIndex_mixin(Topics_index, index) }}
              </span>

              <span class="topic-nav-button" @click="newTopic(topics.type)">
                <i class="el-icon-plus"></i>
              </span>
              <el-button  @click="newTopicByTopic(topics.type)">
                <i class="el-icon-plus"></i>从题库添加
              </el-button>
            </span>
          </div>
        </div>
        <!--    <ckeditor v-model="editorData" :config="editorConfig" :editor-url="editorUrl"></ckeditor>-->
        <div class="topics"  :style="{ fontFamily: testData.selectedFontStyle, fontSize:  testData.fontSize + 'px' }" >
          <!-- 所有题目 -->
          <div
            class="topic"
            v-for="(s_topics, index) in sortedTopics"
            :key="index"
          >
            <!-- 按类型分类好的题目 -->
            <div class="topicsType" v-if="s_topics.topic_content.length != 0">
<!--              大题号与统一设置分数按钮-->
              <div class="bigQuestionName">
<!--                计算大题号-->
                <h4 >{{ bigQuestionName_mixin(s_topics.type, index) }}</h4>
                <!-- 题目类型名称 -->
                <div class="allScore">
<!--                  统一设置分数按钮-->
<!--                  <el-button-->
<!--                    :class="s_topics.showAllScore ? 'active' : ''"-->
<!--                    @click="s_topics.showAllScore = !s_topics.showAllScore"-->
<!--                  >-->
<!--                    统一设置题目分数-->
<!--                  </el-button>-->
                  <el-input
                    v-if="s_topics.showAllScore"
                    @change="setAllScore($event, s_topics.type)"
                    v-model="s_topics.score"
                    placeholder="请输入分数"
                  ></el-input>
                </div>
              </div>

             <!--题目类型判断-->
              <div
                v-for="(t, tIndex) in s_topics.topic_content"
                :key="tIndex"
                @click="edit(s_topics.type, tIndex)">
                <div
                  class="topic-content highlight-border"
                  :class="isEdit(s_topics.type, tIndex) ? 'isEdit' : ''">

                  <!-- 题目 -->
                  <div class="question" >
                    <strong class="question_nunber"  >{{topicNavIndex_mixin(s_topics.type, tIndex) }}、</strong>
                    <span v-if="isEdit(s_topics.type, tIndex)">
<!--                      <el-input-->
<!--                        v-model="t.question"-->
<!--                        type="textarea"-->
<!--                        autosize-->
<!--                      ></el-input>-->
                      <ckeditor  v-model="t.question" :config="editorConfig"></ckeditor>
                    </span>
                    <span v-else :style="{ fontFamily:testData.selectedFontStyle, fontSize:testData.fontSize+'px' }" v-html="t.question"></span>
                  </div>

                  <!-- 单项选择题 -->
                  <div class="userAnswer" v-if="s_topics.type == 0" >
                    <div class="radios" >
                      <el-radio
                        v-for="(item, index) in t.choice"
                        :key="index"
                        v-model="t.correctAnswer"
                        :label="item"
                      >
                        <span class="topicNavIndex" >{{ String.fromCharCode(65 + index) }}、</span>
                        <span
                          v-if="
                            editInedx.type == 0 && editInedx.index == tIndex
                          "
                        >
                          <el-input
                            v-model="t.choice[index]"
                            type="textarea"
                            autosize
                          ></el-input>
                          <el-button
                            class="delRadios"
                            size="mini"
                            type="danger"
                            v-if="t.choice.length > 2"
                            @click="delRadios(0, tIndex, index)"
                          >
                            <i class="el-icon-close"></i>
                          </el-button>
                        </span>
                        <span v-else :style="{ fontFamily: testData.selectedFontStyle, fontSize:  testData.fontSize + 'px' }" >{{ item }}</span>
                      </el-radio>
                    </div>
                    <el-button
                      class="addRadios"
                      size="mini"
                      icon="el-icon-plus"
                      @click="addRadios(s_topics.type, tIndex)"
                      >添加选项</el-button
                    >
                  </div>

                  <!-- 多项选择题 -->
                  <div class="userAnswer" v-if="s_topics.type == 1">
                    <div class="checkbox" >
                      <el-checkbox-group v-model="t.correctAnswer">
                        <el-checkbox
                          :label="item"
                          v-for="(item, index) in t.choice"
                          :key="index"
                        >
                          <span class="topicNavIndex"
                            >{{ String.fromCharCode(65 + index) }}、</span
                          >
                          <span
                            v-if="
                              editInedx.type == 1 && editInedx.index == tIndex
                            "
                          >
                            <el-input
                              v-model="t.choice[index]"
                              type="textarea"
                              autosize
                            ></el-input>
                            <el-button
                              class="delRadios"
                              size="mini"
                              type="danger"
                              v-if="t.choice.length > 2"
                              @click="delRadios(1, tIndex, index)"
                            >
                              <i class="el-icon-close"></i>
                            </el-button>
                          </span>
                          <span v-else :style="{ fontFamily: testData.selectedFontStyle, fontSize:  testData.fontSize + 'px' }" >{{ item }}</span>
                        </el-checkbox>
                      </el-checkbox-group>
                    </div>
                    <el-button
                      class="addRadios"
                      size="mini"
                      icon="el-icon-plus"
                      @click="addRadios(s_topics.type, tIndex)"
                      >添加选项</el-button
                    >
                  </div>

                  <!-- 判断题 -->
                  <div class="userAnswer" v-if="s_topics.type == 2" >
                    <div class="TrueOrFalse">
                      <el-radio v-model="t.correctAnswer" label="true"
                        >正确</el-radio
                      >
                      <el-radio v-model="t.correctAnswer" label="false"
                        >错误</el-radio
                      >
                    </div>
                  </div>

                  <!-- 填空题 -->
                  <div class="userAnswer" v-if="s_topics.type == 3">
                    <el-button size="mini" @click="addFillSymbol(tIndex)"
                      >插入填空符</el-button
                    >
                    <span style="font-size: 12px"
                      >(三个下划线为一个填空符)</span
                    >

                    <div class="fillInBlank">
                      <div
                        v-for="(q, index) in fillSymbolStr(t.question)"
                        :key="index"
                      >
                        <el-input
                          type="textarea"
                          autosize
                          placeholder="请回答"
                          :style="{ fontFamily: testData.selectedFontStyle, fontSize:  testData.fontSize + 'px' }"
                          v-if="index != fillSymbolStr(t.question).length - 1"
                          v-model="t.correctAnswer[index]"
                        >
                        </el-input>
                      </div>
                    </div>
                  </div>

                  <!-- 简答题 -->
                  <div class="userAnswer" v-if="s_topics.type == 4">
                    <div class="text">
                      <strong>&nbsp;&nbsp;关键字</strong>
                      <div v-for="(q, index) in t.correctAnswer" :key="index">
                        <el-input
                          type="textarea"
                          autosize
                          placeholder="请输入关键字"
                          :style="{ fontFamily: testData.selectedFontStyle, fontSize:  testData.fontSize + 'px' }"
                          v-model="t.correctAnswer[index]"
                        >
                        </el-input>
                      </div>
                      <el-button
                        class="addRadios"
                        size="mini"
                        icon="el-icon-plus"
                        @click="addKeyWord(tIndex)"
                        >添加关键字</el-button
                      >
                      <!-- {{t.correctAnswer}} -->
                    </div>
                  </div>

                  <!-- 正确答案 -->
                  <p class="correctAnswer" :style="{ fontFamily: testData.selectedFontStyle, fontSize:  testData.fontSize + 'px' }">
                    <strong>正确答案: </strong>
                    {{ t.topicType===2?t.correctAnswer==='true'?'正确':'错误':t.correctAnswer }}

                  </p>
                  <div>
                    <div class="difficulty" :style="{ fontFamily: testData.selectedFontStyle, fontSize:  testData.fontSize + 'px' }">
                      <strong>难度: </strong>
                      <span v-if="isEdit(s_topics.type, tIndex)">
                        <el-select
                          v-model="t.difficulty"
                          placeholder="请选择"
                          size="mini"
                        >
                          <el-option
                            v-for="item in topicDifficultyOptions"
                            :key="item"
                            :label="item"
                            :value="item"
                          >
                          </el-option>
                        </el-select>
                      </span>
                      <span v-else>{{ t.difficulty }}</span>
                    </div>
                    <div class="topicScore" :style="{ fontFamily: testData.selectedFontStyle, fontSize:  testData.fontSize + 'px' }">
                      <strong>分值: </strong>
                      <span v-if="isEdit(s_topics.type, tIndex)">
                        <el-input-number
                          v-model="t.score"
                          controls-position="right"
                          :step="1"
                          size="mini"
                          :min="0"
                        />
                      </span>
                      <span v-else>{{ t.score }}</span>
                      (分)
                    </div>
                    <div class="required" :style="{ fontFamily: testData.selectedFontStyle, fontSize:  testData.fontSize + 'px' }">
                      <strong>是否必填: </strong>
                      <el-switch
                        v-model="t.required"
                        active-color="#409EFF"
                        inactive-color="#ccc"
                      >
                      </el-switch>
                    </div>
                  </div>
                  <div class="analysis"  v-if="testData" :style="{ fontFamily: testData.selectedFontStyle, fontSize:  testData.fontSize + 'px' }" >
                    <strong>题目解析: </strong>
                    <span v-if="isEdit(s_topics.type, tIndex)">
                      <el-input
                        v-model="t.analysis"
                        type="textarea"
                        autosize
                      ></el-input>
                    </span>
                    <span v-else>{{ t.analysis }}</span>
                  </div>
                </div>
                <el-divider></el-divider>
              </div>
            </div>
          </div>
        </div>


        <div style="clear: both"></div>
      </div>
<!--        返回页面顶端-->
      <div class="back-top" @click="backTop_mixin()">
        <i class="el-icon-arrow-up"></i>
      </div>
    </div>


    <el-dialog title="试题" :visible.sync="dialogFormVisible" width="60%" :close-on-click-modal="false">
      <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="topicId" label="ID" width="80" sortable></el-table-column>
        <el-table-column prop="paper" label="归属试卷" width="200" sortable>
          <template slot-scope="scope">
            <el-tag v-for="p in scope.row.paper" :key="p">{{ p.paperName }}&nbsp;&nbsp;</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="question" label="题目" width="200" sortable>
          <template slot-scope="scope">
            <span  v-html="scope.row.question"> </span>
          </template>
        </el-table-column>
        <el-table-column prop="topicType" label="类型" width="80" sortable>
          <template slot-scope="scope">
            <span v-if="scope.row.topicType===0">单选题</span>
            <span v-if="scope.row.topicType===1">多选题</span>
            <span v-if="scope.row.topicType===2">判断题</span>
            <span v-if="scope.row.topicType===3">填空题</span>
            <span v-if="scope.row.topicType===4">简答题</span>
          </template>
        </el-table-column>
        <el-table-column prop="score" label="分数" width="80" sortable></el-table-column>
        <el-table-column prop="difficulty" label="难度" width="80" sortable></el-table-column>
        <el-table-column prop="correctAnswer" label="正确答案" sortable>
          <template slot-scope="scope">
            <span  v-html="scope.row.correctAnswer"> </span>
          </template>
        </el-table-column>
        <el-table-column prop="analysis" label="答案分析" sortable>
          <template slot-scope="scope">
            <span  v-html="scope.row.analysis"> </span>
          </template>
        </el-table-column>
      </el-table>
      <div style="padding: 10px 0">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[2, 5, 10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import CKEditor from "ckeditor4-vue";
import "@/assets/less/testPaper.less";
import testPaperMixin from "@/mixins/testPaper-mixin.js";
var userName = JSON.parse(localStorage.getItem("user")).username// 要保证取到
export default {
  name: "TestPaperTch",
  mixins: [testPaperMixin],
  components: {
    // Use the <ckeditor> component in this view.
    // ckeditor: CKEditor.component,
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      multipleSelection: [],
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      dialogFormVisible:false,
      editMode: false,  // 编辑状态标志位
      editedQuestion: '',
      editorData:'',//符文本编辑器的内容
      //编译器的网址
      editorUrl:'http://localhost:8080/ckeditor/ckeditor.js',
      editorConfig:{
        removeButtons:'Source,Templates,Save,Print,PasteText,Scayt,Language,ShowBlocks,About',
        // 工具栏组
        toolbarGroups:[
          { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
          { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
          { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
          { name: 'forms', groups: [ 'forms' ] },
          { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
          { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
          { name: 'links', groups: [ 'links' ] },
          { name: 'insert', groups: [ 'insert' ] },
          { name: 'styles', groups: [ 'styles' ] },
          { name: 'colors', groups: [ 'colors' ] },
          { name: 'tools', groups: [ 'tools' ] },
          { name: 'others', groups: [ 'others' ] },
          { name: 'about', groups: [ 'about' ] },
            {name:'math',groups:['MathType']},
        ],
        removePlugins: 'heading, link, list',
        allowedContent: true,
      },//富文本编辑器的配

      //按题目类型分类好的题目数据
      //题目类型==>  0:单选题  1:多选题  2:判断题  3:填空题  4:简答题
      sortedTopics: [
        { type: 0, topic_content: [], score: 0, showAllScore: false },
        { type: 1, topic_content: [], score: 0, showAllScore: false },
        { type: 2, topic_content: [], score: 0, showAllScore: false },
        { type: 3, topic_content: [], score: 0, showAllScore: false },
        { type: 4, topic_content: [], score: 0, showAllScore: false },
      ],
      selectedFontStyle: 'Arial, sans-serif',
      fontSize: 16,
      // //试卷数据
      testData: {
        selectedFontStyle: 'Arial, sans-serif',
        fontSize: 16,
        paperId: -1,
        type:null,
        paperName: "111", //试卷名称
        time: 60, //答题时间
        topicNum: 0, //题目数量
        passMark: 0,
        creatorName: userName,
        // creatorName: this.$store.state.userName,
      },

      //用户数据
      userData: {
        id: "",
        name: "admin",
      },

      //当前编辑的题目索引
      editInedx: {
        type: "-1",
        index: "-1",
        input: "-2",
      },
      examId:new Date().getDate(),

      topicDifficultyOptions: ["简单", "中等", "困难"], //题目难度选项

      //侧导航栏是否悬浮
      isFixed: false,
      topic_nav_style: "top:0px",
      topicTye:0,
    };
  },
  watch: {
    sortedTopics: {
      handler(newValue, oldValue) {
        console.log(this.examId + ' sortedTopics 发生变化:', oldValue, ' => ', newValue);
        localStorage.setItem(this.examId + 'sortedTopics', JSON.stringify(newValue));
      },
      deep: true
    },
    testData: {
      handler(newValue, oldValue) {
        console.log(this.examId + ' testData 发生变化:', oldValue, ' => ', newValue);
        localStorage.setItem(this.examId + 'testData', JSON.stringify(newValue));
      },
      deep: true
    }
  },
  computed: {
    params () {
      return this.$route.params;
    },
    //试卷总分
    totalScore () {
      var score = 0;
      this.sortedTopics.forEach((element) => {
        element.topic_content.forEach((item) => {
          // console.log(typeof(item.score));
          score += parseInt(item.score);
        });
      });
      this.testData.totalScore = score;
      return score;
    },

    //按填空符(三个下划线)划分字符串
    fillSymbolStr () {
      return function (str) {
        var q = str.split("___");
        return q;
      };
    },
  },

  created () {
    let type = this.$route.params.type;
    if (type === "add") {
    }
    if (type === "edit") {
      console.log("执行获取试卷函数")
      this.getTestPaper();
    }
  },

  mounted () {
    // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    load() {
      this.request.get("/topic/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          topicTye: this.topicTye,
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    // 复制试卷
    copy () {
      this.testData.examName = "点击编辑试卷名称";
      this.$router.push({
        name: "createPaper",
        params: { type: "add", data: this.testData },
      });
      this.$message.success("复制成功");
    },

    viewTestPaper () {
      console.log("预览试卷id=" + this.examId)
      const { href } = this.$router.resolve({
        name: "viewPaper",
        params: { tp_id:this.examId }
      });
      window.open(href, "_blank");
    },
    //提交试卷
    submit () {
      /*  处理题目信息 */
      var topicData = [];
      this.sortedTopics.forEach((element) => {
        if (element.topic_content.length != 0) {
          element.topic_content.forEach((item) => {
            //深拷贝
            var newItem = JSON.parse(JSON.stringify(item));
            topicData.push(newItem);
          });
        }
      });

      //处理选择题选项
      topicData.forEach((item) => {
        var choice = "";
        if (item.choice) {
          item.choice.forEach((c) => {
            choice += c + "\n";
          });
        }
        item.choice = choice.slice(0, -1);
        item.required = item.required === true ? 1 : 0
      });

      //处理正确答案
      for (let item of topicData) {
        if (!item.correctAnswer) {
          this.$message.warning("有题目未选答案");
          return
        }
        if (item.correctAnswer instanceof Array) {
          if (item.correctAnswer.length === 1 && item.correctAnswer[0] === '') {
            this.$message.warning("有题目未选答案");
            return
          }
          var correctAnswer = "";
          item.correctAnswer.forEach((c) => {
            correctAnswer += c + "\n";
          });
          item.correctAnswer = correctAnswer.slice(0, -1);
        }
      };

      //处理试卷信息
      console.log("处理之前的testdate=" + JSON.stringify(this.testData));
      console.log("处理之前=this.testData.creatorId=" + this.testData.creatorId)
      var testData = JSON.parse(JSON.stringify(this.testData));

      testData.topicTchDTOList = topicData;
      testData.creatorId = testData.creatorId;
      testData.creatorName = testData.creatorName;
      // this.$store.state.userName;
      testData.topicNum = topicData.length;

      console.log("处理后testdate=" + testData);

      var requestData = {
        ...testData,
      };

      if (this.params.type === "edit") {
        var url = "/paper/updateTestPaper";
      } else {
        var url = "/paper/createTestPaper";
      }
      console.log("url=" + url)
      console.log("data =" , requestData);
      console.log("data =" + JSON.stringify(requestData));
      this.request.post(url, requestData).then((res) => {
        console.log("res.code=" + res.code)
        // console.log("res.resultmsg=" + res.resultmsg)
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.examId = res.data.paperId
          // if (url == "/paper/createTestPaper") {
          //   this.$router.push("/paper/testPaperTch/edit/" + res.data.paperId).catch(err => (console.log("err=" + err)));
          // }
        }
        else {
          this.$message.error(res.code + ":" + res.msg)

        }
      });
    },

    //编辑试卷---获取试卷信息
    async getTestPaper () {
      let params = {
        examId: this.$route.params.tp_id,
      };
      await this.request.get("/paper/getTestPaperByTp_id", { params }).then((res) => {
        // this.testData = res.data
        console.log("res.data ==> ", res);
        if (res.code == 200) {
          var resTestData = res.data;
        } else {
          return;
        }

        //处理试卷的题目数据
        resTestData.topicTchDTOList.forEach((item) => {
          if (item.topicType == 4 || item.topicType == 3 || item.topicType == 1) {
            if (item.correctAnswer!=null&&item.correctAnswer!='undefined') {
              item.correctAnswer = item.correctAnswer.split(/[\n]/g);
            }
          }
          //按换行符分割字符串
          if (item.choice) {
             item.choice = item.choice.split(/[\n]/g);
          }
          item.required = item.required === 1 ? true : false;
        });
        this.testData = resTestData;
        console.log("testData ==> ", this.testData);

        //按题目类型分类并保存
        var topics = this.testData.topicTchDTOList;
        this.examId = this.testData.paperId
        for (let i = 0; i < topics.length; i++) {
          for (let item of this.sortedTopics) {
            //     // console.log(topics[i].topicType,item.type);
            if (topics[i].topicType == item.type) {
              item.topic_content.push(topics[i]);
            }
          }
        }
        console.log(this.sortedTopics);
      });
    },

    //统一设置题目分数
    setAllScore (val, type) {
      val = parseInt(val);
      this.sortedTopics[type].topic_content.forEach((item) => {
        item.score = val;
      });
    },

    //设置当前编辑中的题目
    edit (type, index) {
      if (this.editInedx.type == type && this.editInedx.index == index) {
        console.log("拦截");
        return;
      }
      this.editInedx.type = type;
      this.editInedx.index = index;
    },

    //是否在编辑状态
    isEdit (type, index, input = -2) {
      if (
        this.editInedx.type == type &&
        this.editInedx.index == index &&
        this.editInedx.input == input
      ) {
        return true;
      } else {
        return false;
      }
    },

    //滚动事件
    handleScroll () {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量
      if (scrollTop > 154) {
        this.topic_nav_style = "top:" + (scrollTop + 20) + "px";
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },

    //点击题号导航跳转
    topicNav (type, index) {
      var totalIndex = this.topicNavIndex_mixin(type, index) - 1;
      setTimeout(() => {
        document
          .getElementsByClassName("topic-content")
        [totalIndex].scrollIntoView({ behavior: "smooth" });
      }, 100);
      this.edit(type, index);
    },

    //新建题目
    // 选择题
    newTopic (type) {
      this.sortedTopics[type].topic_content.push({
        u_id: this.userData.id,
        topicType: type,
        question: "这是我新建的题目",
        choice: ["选项1", "选项2", "选项3", "选项4"],
        correctAnswer: [],
        analysis: "这是答案分析",
        difficulty: "中等",
        score: 10,
        required: true
      });

      var time = setTimeout(() => {
        this.topicNav(type, this.sortedTopics[type].topic_content.length - 1);
        clearInterval(time);
      }, 100);
    },
    save(){
      console.log(this.multipleSelection);
      if (!this.multipleSelection||this.multipleSelection.length<1){
        this.$message.warning("请选择");
        return;
      }
      for (let   i = 0; i < this.multipleSelection.length; i++){
        let  correctAnswer;
        if (this.multipleSelection[i].topicType == 4 || this.multipleSelection[i].topicType == 3 || this.multipleSelection[i].topicType == 1) {
          if (this.multipleSelection[i].correctAnswer!=null&&this.multipleSelection[i].correctAnswer!='undefined') {
            correctAnswer = this.multipleSelection[i].correctAnswer.split(/[\n]/g);
          }else {
            correctAnswer = []
          }
        }else {
          correctAnswer = this.multipleSelection[i].correctAnswer
        }
        console.log(this.multipleSelection[i])
        console.log(correctAnswer)
        this.sortedTopics[this.topicTye].topic_content.push({
          u_id: this.userData.id,
          topicType: this.topicTye,
          question: this.multipleSelection[i].question,
          choice: this.multipleSelection[i].choice?this.multipleSelection[i].choice.split(/[\n]/g):["选项1", "选项2", "选项3", "选项4"],
          correctAnswer: correctAnswer,
          analysis: this.multipleSelection[i].analysis,
          difficulty: this.multipleSelection[i].difficulty,
          score: this.multipleSelection[i].score,
          required:  this.multipleSelection[i].required=== 1 ? true : false
        });
      }
      this.dialogFormVisible = false;
    },
    newTopicByTopic(type){
      this.dialogFormVisible = true;
      this.multipleSelection = [];
      this.topicTye = type;
      this.load();
      this.$nextTick(() => {
        if(this.$refs.img) {
          this.$refs.img.clearFiles();
        }
        if(this.$refs.file) {
          this.$refs.file.clearFiles();
        }
      })
    },

    //删除当前正在编辑的题目
    delTopic () {
      var type = this.editInedx.type;
      var index = this.editInedx.index;
      this.sortedTopics[type].topic_content.splice(index, 1);
    },

    //上移或下移当前正在编辑的题目  -1:上移   1:下移
    moveTopic (n) {
      var type = this.editInedx.type;
      var index = this.editInedx.index;

      if (index == 0 && n == -1) {
        console.log("拦截");
        return;
      }
      if (index == this.sortedTopics[type].topic_content.length - 1 && n == 1) {
        console.log("拦截");
        return;
      }

      var topic = this.sortedTopics[type].topic_content[index];
      var upTopic = this.sortedTopics[type].topic_content[index + n];

      this.sortedTopics[type].topic_content.splice(index, 1, upTopic);
      this.sortedTopics[type].topic_content.splice(index + n, 1, topic);

      var time = setTimeout(() => {
        this.topicNav(type, index + n, 10);
        clearInterval(time);
      }, 100);
    },

    //删除选项
    delRadios (type, tIndex, index) {
      this.sortedTopics[type].topic_content[tIndex].choice.splice(index, 1);
    },

    //添加选项
    addRadios (type, tIndex) {
      var choiceLength =
        this.sortedTopics[type].topic_content[tIndex].choice.length + 1;
      if (choiceLength > 4) {
        this.$message({
          message: "不能再添加选项了喔!",
          type: "warning",
        });
        return;
      }
      this.sortedTopics[type].topic_content[tIndex].choice.push(
        "选项" + choiceLength
      );
    },

    //添加填空符
    addFillSymbol (tIndex) {
      var str = this.sortedTopics[3].topic_content[tIndex].question;
      if (str.lastIndexOf('</p>')!==-1){
        str = str.substring(0,str.lastIndexOf('</p>'))+'___</p>'
      }else {
        str = str+"___";
      }
      this.sortedTopics[3].topic_content[tIndex].question = str ;
    },

    //添加关键字
    addKeyWord (tIndex) {
      this.sortedTopics[4].topic_content[tIndex].correctAnswer.push("");
      console.log(this.sortedTopics[4].topic_content[tIndex]);
    },
    //退出
    logout() {
      this.$router.push({
        name:"logout"
      })
      this.$router.push({path:"/home"})
      this.$message.success("退出成功")
    },
  },
};
</script>
<style scoped>
.highlight-border {
  border: 2px solid transparent; /* 初始状态下透明边框 */
  transition: border-color 0.3s ease; /* 过渡效果 */
}
.highlight-border:hover {
  border-color: #5c84ff; /* 鼠标悬停时边框颜色变为红色 */
}
</style>
